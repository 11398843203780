.slick-track {
    height: 246px;
  }

  .slick-prev {
    z-index: 100;
    left: 20px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    width: 51px;
    height: 51px;
    border-radius: 50px;
    top: 40%;
  }
  .slick-next {
    z-index: 100;
    right: 20px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    width: 51px;
    height: 51px;
    border-radius: 50px;
    top: 40%;
  }
  
  .slick-prev:before {
    content: "<";
    color: var(--main);
    font-size: 30px;
    display: table;
    margin: 8px auto;
    font-weight: bolder;
    margin: 0 0px 3px 15px;
  }
  
  .slick-next:before {
    content: ">";
    color: var(--main);
    font-size: 30px;
    display: table;
    margin: 8px auto;
    font-weight: bolder;
    margin: 0 0px 3px 18px;
  }